.app-wrapper{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    position: relative;
}

.app-router-wrapper{
    width: 100%;
    height:calc(100% - 60px);
    background-color: #FFFFFF;
}

.app-navigation-wrapper{
    width: 100%;
    height:60px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    z-index: 50;
}

.app-navigation-button-link{
    height: 100%;
    flex:1;
    text-decoration: none !important;
    background-color: transparent !important;
}

.app-navigation-button-link a:active{
    background-color: transparent !important;
}

.app-navigation-button{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app-navigation-button-icon{
    font-size: 18px;
    color:#797979;
}

.app-navigation-button-text{
    text-align: center;
    font-size: 14px;
    color:#222222;
}

.active .app-navigation-button-icon, .active .app-navigation-button-text{
    color:#1079FC !important;
}

.card-shadow{
    box-shadow: 0 2px 3px rgba(0,0,0,0.2)
}
