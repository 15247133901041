.favorites-mode-button{
    height: 100%;
    padding-left: 14px !important;
    padding-right: 14px !important;
    position: relative;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.favorites-mode-button span{
    font-weight: bold;
    color: #555555;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.favorites-mode-button-bottom-bar{
    position: absolute;
    left:8px;
    bottom:0;
    right:8px;
    height:2px;
    width: calc(100% - 16px);
    background-color: #1079FC;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    opacity: 0;
}


.favorites-mode-button-selected span{
    color: #1079FC;
}

.favorites-mode-button-selected .favorites-mode-button-bottom-bar{
    opacity: 1;
}

.route-fav-row{
    width: 100%;
    height:54px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 12px;
    border-bottom: 1px solid #EFEFEF;
}

.favorite-route-icon{
    font-size: 18px;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    color: #CCCCCC;
}

.favorite-route-icon:active{
    transform: scale(1.2);
}

.favorite-route-icon-fav{
    color: #1079FC;
}

.no-stop-fav{
    width: 50%;
    max-width: 350px;
}

.favorites-mode-search{
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    transform: scale(0);
    transform-origin: center;
}

.favorites-mode-search-displayed{
    transform: scale(1);
}

.clear-search-icon{
    font-size: 24px !important;
    color: #555555 !important;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.clear-search-icon-disabled{
    color:#CCCCCC !important;
}

.floating-shadowed{
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;;
}
