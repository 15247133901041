.homepage-wrapper{
    background-color: #f2f2f2;
}

.alert-image{
    width: 100%;
    height:180px;
    background-image: url("./../../assets/rdta/verglas.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    padding: 8px;
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
}

.map-background{
    background-image: url("./screen-map.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border:1px solid #CCCCCC !important;
    border-radius: 4px;
    position: relative;
}

.pulsating-alert-circle {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    background-color: #FFFFFF;
    position: absolute;
    z-index:50;
}

.pulsating-alert-circle-hidden{
    opacity: 0;
}

.pulsating-alert-circle:before {
    content: "";
    position: relative;
    display: block;
    width: 80px;
    height: 80px;
    box-sizing: border-box;
    margin-left: -20px;
    margin-top: -20px;
    border-radius: 140px;
    background-color: #018D76;
    -webkit-animation: pulse-ring 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    animation: pulse-ring 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

@keyframes pulse-ring {
    0% {
        transform: scale(0.2);
    }
    80%, 100% {
        opacity: 0;
    }
}
